.miscellaneousWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.eachSection {
  /* // color: #333; */
  margin: 12px 24px;
  font-weight: 400;
  width: 100%;
  /* // height: 14rem; */
  border: 2px solid var(--color-on-surface);
  border-radius: 8px;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.eachSection > .eachSectionDivs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .eachSection > .eachSectionDivs {
    flex-direction: row;
  }
}