/* ========================================================= */
/* ========================================================= */
/* ========================================================= */
@keyframes circular-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.animate-circular-rotate {
  animation: circular-rotate 1.4s linear infinite;
}