.drawer {
  position: fixed;
  top: 0;
  /* width: 250px; */
  width: max-content;
  height: 100vh;
  background-color: var(--color-surface);
  color: var(--color-on-surface);
  transition: transform var(--transition-main) ease; /* Slide-in effect */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); */
  overflow-y: auto; /* Allow scrolling if content is long */
  z-index: 1000;
}

/* Right-to-Left Drawer */
.drawer.rtl {
  right: 0;
  transform: translateX(100%);
}
.drawer.rtl.open {
  transform: translateX(0);
}

/* Left-to-Right Drawer */
.drawer.ltr {
  left: 0;
  transform: translateX(-100%);
}
.drawer.ltr.open {
  transform: translateX(0);
}

.close-button {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  margin: 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.close-button:hover {
  filter: brightness(0.9);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
}