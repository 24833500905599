.iconButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 8px;
    margin: 8px;
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.iconButton:hover {
    background-color: var(--color-muted);
    color: var(--color-primary);
}

.smallIconButton {
    width: 40px;
    height: 40px;
}
.mediumIconButton {
    width: 50px;
    height: 50px;
}
.largeIconButton {
    width: 60px;
    height: 60px;
}