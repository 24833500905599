.sectionContainer {
  background-color: var(--color-background);
  width: 100%;
  /* height: 80vh; */
  padding: 10vh 0 20vh 0;
}

.stepButtonGroup {
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
  /* border-bottom: 2px solid #6b5be6; */
}

.stepButton {
  font-size: 1.05rem;
  font-weight: 500;
  padding: 8px 24px;
  background-color: transparent;
  border-radius: 5px 5px 0px 0px;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0.1rem;
  color: var(--color-on-background);
  border-bottom: 2px solid var(--color-primary);
}

.stepButtonActive {
  transition: 200ms;
  color: var(--color-on-primary);
  /* background-color: #f0eeff; */
  background-color: var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
}

.stepButton:hover {
  transition: 100ms;
  cursor: pointer;
  /* background-color: #ddd; */
}

.buttonEmoji {
  font-size: 20px;
}

.howToSectionImageHeader {
  margin: 12px 0px 24px 0px;
  color: var(--color-on-surface-header);
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (max-width: 900px) {
  .stepButtonGroup {
    width: 30rem;
    flex-wrap: wrap;
  }
  .stepButton {
    flex: 50%;
  }
}

@media (max-width: 400px) {
  .stepButtonGroup {
    flex-wrap: wrap;
  }
  .stepButton {
    flex: 100%;
  }
}
