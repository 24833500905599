.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: var(--color-background);
}

.pdfOuterDiv {
  position: relative;
  background-color: white;
  border-radius: 5px;
  width: max-content;
  width: 210mm;
  /* transform: TranslateX(-10px); */
  /* boxShadow: 1px 2px 2px 2px #000; */
  margin: 20px 0px 0px 0px;
  border: 1px solid var(--color-primary);
  /* overflow: hidden; */
  overflow-y: scroll;
}

#pdfInnerDiv {
  position: relative;
  /* width: 210mm; */
  /* min-height: 297mm; */
  margin: 20px 20px 20px 20px;
  aspect-ratio: calc(210/297);
  /* overflow: hidden; */
}
/* @media print {
  html, body { */
    /* Use 100% here to support printing more than a single page*/
    /* height: auto;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
} */
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
  }
}
/* @media print {
  #pdfInnerDiv {
    page-break-inside: avoid;
    min-height: 297mm;
  }

  #pdfInnerDiv > div {
    page-break-inside: avoid;
    break-inside: avoid;
    min-height: 2mm;
    page-break-before: auto;
    page-break-after: auto;
    break-before: auto;
    break-after: auto;
  }
} */

.pdfWatermark {
  font-size: 3mm;
  text-align: center;
  position: absolute;
  bottom: 0mm;
  width: 100%;
  color: #5b6be6;
}

.buttonGroupDownloadPage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 20px 0px;
}
.buttonGroupDownloadPage button {
  color: var(--color-background);
  background-color: var(--color-primary);
}
.buttonGroupDownloadPage button:hover {
  filter: brightness(80%);
  color: var(--color-background);
  background-color: var(--color-primary);
  transition: 300ms;;
}