.ppTncPageWrapper{
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: var(--color-background);
}

.ppTncSectionWrapper {
  /* margin: 20px 0px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 24px;
  font-weight: 400;
  font-size: medium;
}

.ppTncPageHeader {
  font-weight: 600;
  font-size: x-large;
  color: var(--color-primary);
  width: 100%;
  text-align: center;
  margin: 0px 0px 12px 0px;
}
.ppTncPageSubHeader {
  font-weight: 500;
  font-size: large;
  color: var(--color-on-surface-header);
  width: 100%;
  text-align: center;
}

.listOrdered {
  list-style-type: decimal;
  margin-left: 20px;
}
.listOrdered > li {
  margin: 10px 0px;
}
.ulHeader{
  font-weight: 600;
  font-size: large;
  color: var(--color-on-surface-header);
}

.listUnordered {
  list-style-type: disc;
  margin-left: 20px;
  color: var(--color-on-background);
}

@media screen and (min-width: 900px) {
  .ppTncSectionWrapper {
    width: 60%;
  }
}