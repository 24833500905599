.leftMenuGridContainer {
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); */
  grid-template-columns: repeat(3, 1fr); /* Adjust minmax value as needed */
  gap: 8px; /* Adjust spacing between items */
  padding-top: 8px; /* Optional: Add some padding to the top */
}

.leftMenuEachIcon {
  background-color: var(--color-surface);
  margin: 5px 5px 20px 5px;
  border: 2px solid var(--color-primary);
  /* box-shadow: 0px 10px 15px -3px rgba(36, 69, 101, 0.19), 0px 4px 6px -2px #d0dce8; */
  backface-visibility: hidden;
  border-radius: 5px;
  transition: var(--transition-fast) linear;
}
.leftMenuEachIcon:hover {
  transform: scale(1.1);
  transition: var(--transition-fast) linear;
  cursor: pointer;
}

.leftMenuEachSelectedIcon {
  background-color: var(--color-muted);
  margin: 5px 5px 20px 5px;
  border-radius: 5px;
  /* Add any necessary styles for the individual items here */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  cursor: not-allowed;
}

/* ========================================================================= */
/* ========================================================================= */
/* ========================================================================= */

.avatar {
  margin: 5px;
  background-color: #ff9800;
}
.form {
  /* width: 100%; // Fix IE 11 issue. */
  margin-top: 5px;
}
.getReusmeButton {
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #00ccc9;
  padding: 12px;
}
.categoryTitle {
  margin-left: 8px;
  color: var(--color-on-surface);
  margin-top: 36px;
}
