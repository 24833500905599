.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
}
.switchLabel {
  margin: 0px 0px 0px 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-on-surface);
}


/* THE CUSTOM SWITCH */
.custom-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* unchecked */
.custom-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-on-surface);
  transition: 0.4s;
  border-radius: 34px;
}
/* checked */
.custom-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: var(--color-on-primary);
  transition: 0.4s;
  border-radius: 50%;
  border: transparent;
}
/* Active background color */
input:checked + .custom-slider {
  background-color: var(--color-primary) !important; 
}

input:checked + .custom-slider:before {
  transform: translateX(24px);
}
