/* .profileCard  {
  width: "80vw",
  padding: "20px",
  margin: "200px 0px 0px 0px",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
} */

.profileCard {
  background-color: var(--color-surface);
  width: 80vw;
  padding: 20px;
  margin: 200px 0px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.eachProfileItem {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.eachProfileItem h3 {
  color: var(--color-primary);
}
.eachProfileItem p {
  color: var(--color-on-surface);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyButton {
  color: var(--color-primary);
  margin: 0px;
  padding: 0px;
}

@media screen and (min-width: 900px) {
  .profileCard {
    width: 60vw;
  }
}

@media screen and (min-width: 1200px) {
  .profileCard {
    width: 40vw;
  }
}
