.App {
  text-align: center;
  /* background-color: black; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**
  * NEEDED BECAUSE HTML2PDF SHIFTS IMAGE DOWN =>
  * REFER TO => https://github.com/eKoopmans/html2pdf.js/issues/659
  * AND => https://github.com/niklasvh/html2canvas/issues/2775
*/
/* img {
  display: inline-block !important;
} */

/* themes.css */
/* :root {
  --color-appbar-bg: #282c34;
  --color-bg: #db3434;
  --color-text: #994cc3;
  --color-primary: #ef5350;
  --color-secondary: #0c969b;
  --color-header-bg: rgba(255, 255, 255, 0.9);
  --color-neon-fill: #ef5350;
  --color-neon-glow: none;
} */
/* Primary, secondary, tertiary(optional) background, muted, success, warning, info, error */
:root {
  /* --color-primary: #1c1917; */
  /* --color-primary: #db3434; */
  /* --color-primary: #ff5722; */
  /* Colors */
  --color-primary: #6b5be6;
  /* on primary colors such as text colors */
  --color-on-primary: #fafafa;
  --color-secondary: #00ccc9;
  --color-on-secondary: #fafafa;
  --color-tertiary: #6b5be6;
  --color-background: #fafafa;
  --color-on-background: #333;
  --color-accent: #f0eeff;
  --color-muted: #eaeaea;
  --color-success: #28a745;
  --color-warning: #ffc107;
  --color-info: #17a2b8;
  --color-error: #dc3545;
  --color-on-error: #ffffff;
  --color-surface: #ffffff;
  --color-on-surface: #555;
  --color-on-surface-header: #333;
  --color-divider: #777;
  --shadow-color: rgba(0, 0, 0, 0.25);
  --box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  /* Transitions */
  --transition-fast: 0.1s;
  --transition-main: 0.25s;
  --transition-medium: 0.4s;
  --transition-slow: 0.5s;
}

.theme-dark {
  /* --color-primary: #0C0A09; */
  --color-primary: #f9f9f9;
  --color-on-primary: #333;
  --color-secondary: #00ccc9;
  --color-on-secondary: #fafafa;
  --color-tertiary: #6b5be6;
  --color-background: #171717;
  --color-on-background: #f0f0f0;
  --color-accent: #f0eeff;
  --color-muted: #222;
  --color-success: #28a745;
  --color-warning: #ffc107;
  --color-info: #17a2b8;
  --color-error: #dc3545;
  --color-on-error: #ffffff;
  --color-surface: #000;
  --color-on-surface: #c9c9c9;
  --color-on-surface-header: #cdcdcd;
  --color-divider: #777;
  --shadow-color: rgba(0, 0, 0, 0.25);
  --box-shadow: 0 1px 3px rgba(255,255,255,0.12), 0 1px 2px rgba(255,255,255,0.24);
}

.theme-old {
  --color-primary: #6b5be6;
  --color-secondary: #00ccc9;
  --color-tertiary: #f0f0f0;
  --color-background: #f8f9fa;
  --color-muted: #6c757d;
  --color-success: #28a745;
  --color-warning: #ffc107;
  --color-info: #17a2b8;
  --color-error: #dc3545;
}
/* :root { */
  /*  Fast Transition */
  /* --f-transition: 0.2s; */
  /* Main Transition */
  /* --main-transition: 0.3s; */
  /* Medium Transition */
  /* --m-transition: 0.4s; */
  /* Slow Transition */
  /* --s-transition: 0.5s; */
  /* --header-height: 60px;
  --sections-padding: 60px; */
  /* --main-color: hsl(206, 92%, 46%); */
  /* --primary-color: #6b5be6;
  --secondary-color: #00ccc9; */
  /* --third-color: hsl(179, 100%, 40%); */
  /* --light-main-color: hsl(206, 92%, 50%); */
  /* --light-primary-color: hsl(247, 74%, 67%);
  --light-secondary-color: #00e0dd;
  --dark-primary-color: #5a4ad5;
  --dark-secondary-color: #03bbb8; */
/* } */

/* Paralegal */
/* @layer base {
  :root {
    --background: 0 0% 99%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 99%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 99%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 240, 6%, 10%;
    --foreground: 0 0% 98%;
    --card: 240, 6%, 10%;
    --card-foreground: 0 0% 98%;
    --popover: 240, 6%, 10%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
} */