.divider {
  /* box-shadow: 3px 0px 3px #d5d5d7, -6px 0px 8px #f1f1fb; */
  width: 1vw;
  /* margin: 0px 8px 0px 0px; */
  background-color: var(--color-surface);
  display: none;
}

.eachFormWrapper {
  box-shadow: 0px 1px 2px #d5d5d7;
  padding: 16px 8px;
  border-radius: 8px;
  background-color: var(--color-surface);
  color: var(--color-on-surface);
  margin: 1rem;
  margin-bottom: 16px;
  /* width: 90%; */
}

.rightFormDrawerCloseButton {
  /* margin-top: 400px; */
  width: 90%;
  margin: auto;
  margin-bottom: 16;
}

#saveResumeNameDialog {
  width: 400;
  padding: 16px;
  font-weight: 500;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.downloadButton {
  width: 100%;
  padding: 0.8rem;
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  font-size: 1rem;
  font-weight: 600;
  font-family: sans-serif, Arial, Helvetica;
  border: 2px solid var(--color-primary);
  border-radius: 6px;
  transition: background-color var(--transition-main), color var(--transition-main);
}
.downloadButton:hover{
  transition: background-color var(--transition-main), color var(--transition-main);
  cursor: pointer;
  background-color: var(--color-surface);
  color: var(--color-primary);
}

.saveButton {
  width: 100%;
  padding: 0.8rem;
  background-color: var(--color-secondary);
  color: var(--color-on-secondary);
  font-size: 1rem;
  font-weight: 600;
  font-family: sans-serif, Arial, Helvetica;
  border: 2px solid var(--color-secondary);
  border-radius: 6px;
  transition: background-color var(--transition-main), color var(--transition-main);
}
.saveButton:hover{
  cursor: pointer;
  background-color: var(--color-surface);
  color: var(--color-secondary);
  transition: background-color var(--transition-main), color var(--transition-main);
}


@media screen and (min-width: 1200px) {
  .divider {
    display: block;
  }
  .rightFormDrawerCloseButton {
    display: none;
  }
}

/* @media (max-width: 1600px) {
  .formWrapper {
    width: 35rem;
  }
}

@media (max-width: 1200px) {
  .formWrapper {
    width: 28rem;
  }
}

@media (max-width: 900px) {
  .formWrapper {
    width: 20rem;
  }
} */
