.textInputWrapper {
  position: relative;
  margin: 6px 0;
  width: 100%;
}

.textInputLabel {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 300;
  color: var(--color-on-surface);
  pointer-events: none;
  transition: all 0.3s ease;
  background-color: transparent;
  padding: 0 4px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textInput {
  width: 100%;
  padding: 20px 12px 6px;
  font-size: 16px;
  border: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  outline: none;
  background-color: var(--color-muted);
  color: var(--color-on-surface-header);
  /* filter: brightness(1.1); */
  transition: background-color 0.3s ease;
}

.textInput:focus {
  background-color: rgba(0, 0, 0, 0.09); /* Slightly darker on focus */
}

/* on focused */
.textInputWrapper.focused .textInputLabel,
.textInput:not(:placeholder-shown) + .textInputLabel {
  top: 10PX;
  left: 8px;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-primary);
  /* Optional: make the label background white for better visibility */
  /* background-color: white; */
}

.textInputWrapper::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 2px;
  width: 0;
  background-color: var(--color-primary); /* Primary color for bottom border */
  transition: width var(--transition-main) ease, left var(--transition-main) ease;
  transform: translateX(-50%);
}

.textInputWrapper.focused::after {
  width: 100%;
  /* left: 0; */
}



/* Multiline */
/* ================================================= */
/* ================================================= */
/* ================================================= */
.textArea {
  resize: vertical;
  font-family: inherit;
}