.blocks {
  background-color: rgb(197, 197, 197);
  color: rgb(0, 0, 0);
}

.createPageWrapper {
  display: flex;
  flex-direction: row;
  height: calc(100% - 72px);
  width: 100vw;
  position: fixed;
  background-color: red;
  overflow-x: clip;
}

.drawerOpenButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.leftMenuDrawerOpenButton {
  margin: 12px .75rem 0px 12px;
  width: 4rem;
}
.rightFormDrawerOpenButton {
  margin: 12px .75rem 0px auto;
  width: 4rem;
}

.leftMenu {
  /* width: 18vw; */
  z-index: 1;
  padding: 0px 5px 0px 5px;
  background-color: var(--color-background);
  border-right: 1px solid var(--color-primary);
  height: 100%;
  overflow-y: scroll;
  display: none;
}
.leftMenu::-webkit-scrollbar {
  display: none;
}

.middleGrid {
  width: 100vw;
  max-height: 400mm;
  overflow-y: scroll;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* overflow-x: hidden;
  overflow-y: scroll; */
  background-color: var(--color-background);
  /* background-color: green; */
}
.middleGrid::-webkit-scrollbar {
  display: none;
}


.rightForm {
  /* width: 42vw; */
  z-index: 1;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: none;
  overflow-x: hidden;
  background-color: var(--color-background);
}
.rightForm::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 800px) {
  .leftMenu {
    display: block;
    width: 18vw;
  }
  .leftMenuDrawerOpenButton {
    display: none;
  }
}

/* Screens Larger Than 1200px */
@media screen and (min-width: 1200px) {
  .leftMenu {
    display: block;
    width: 8vw;
  }
  .rightForm {
    display: block;
    width: 40vw;
    /* background-color: transparent; */
  }
  .middleGrid {
    width: 52vw;
  }
  .drawerOpenButtons {
    display: none;
  }
}

/* .sidebar {
  width: 42vw;
  background-color: transparent;
  position: absolute;
  top: 0;
  overflow-y: scroll;
  height: 100vh;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: right 0.5s linear;
}
.sidebar.hidden {
  right: -100%;
}
.sidebar.visible {
  right: 0%;
}

@media (max-width: 640px) {
  .sidebar {
    width: 72vw;
    background-color: #ebf4ff;
  }
  .middleGrid {
    width: 92vw;
  }
} */

/* @media (max-width: 1600px) {
  .middleGrid {
    width: 60%;
  }

  .rightForm {
    width: 32%;
  }
} */

/* @media (max-width: 1280px) {
  .middleGrid {
    width: 65%;
  }

  .rightForm {
    width: 27%;
  }
} */

a:visited {
  text-decoration: none;
  color: inherit;
}
