/* Start Projects */
#sectionContainer {
  /* padding-top: var(--sections-padding);
  padding-bottom: var(--sections-padding); */
  /* background-color: aliceblue; */
  background-color: var(--color-background);
  width: 100%;
  /* height: 80vh; */
  padding: 10vh 0 20vh 0;
}
.templatesSectionContent {
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.eachTemplate {
  /* position: relative; */
  border-radius: 6px;
  background: var(--color-surface);
  overflow: hidden;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.07); */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 280px;
}
.templateImage {
  display: grid;
  place-items: center;
  padding-top: 24px;
}
.templateImage>img {
  border-radius: 4px;
}

.templateDetails {
  padding: 15px;
  text-align: start;
  /* color: #000; */
}
.templateTitle {
  text-transform: capitalize;
  margin-bottom: 8px;
  color: var(--color-primary);
  font-size: 22px;
  font-weight: 600;
}
.templateDescription {
  font-size: 15px;
  line-height: 1.6;
  color: var(--color-on-surface);
}
.useTemplateButton {
  background-color: var(--color-secondary);
  color: var(--color-on-secondary);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  font-family: monospace;
  padding: 6px 15px;
  border: 1px solid var(--color-secondary);
  margin-top: 20px;
  transition: all ease-in-out var(--transition-fast);
}
.useTemplateButton:hover {
  background-color: var(--color-surface);
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  cursor: pointer;
}
.moreTemplates {
  text-align: center;
}
.moreTemplates a {
  display: inline-block;
  white-space: nowrap;
  margin: 80px auto 0;
}
.moreTemplates:hover i {
  transition: 0.3s;
  transform: translateX(4px);
}

/* ========================XXXXXXXXXX========================= */

.templates-content .template {
  position: relative;
  border-radius: 6px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}

/* End Templates */

@media (max-width: 900px) {
  .templatesSectionContent {
    flex-direction: column;
  }
  .eachTemplate {
    margin-bottom: 2rem;
  }
}
