.menuCustom {
  position: absolute;
  top: 50px; /* Adjust based on anchorEl position */
  left: 0; /* Adjust based on anchorEl position */
  background-color: var(--color-surface);
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 1000;
}

.menu-item {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu-item:hover {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
}

.menuDivider {
  height: 1px;
  background-color: #ddd;
  margin: 4px 0;
  width: 100%;
}