.dialog-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.dialog-container {
  background-color: white;
  /* padding: 20px; */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1001;
  max-width: max-content;
  width: 100%;
}

.dialog-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
