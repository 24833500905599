.form-control {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.radio-group {
  display: flex;
  gap: 24px;
}

.singleRadioInputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.singleRadioInputWrapper:hover{
  cursor: pointer;
}

.singleRadioInputWrapper input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background-color: var(--color-muted);
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.singleRadioInputWrapper input[type="radio"]::before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: var(--color-primary);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.25s ease;
  display: block;
}

.singleRadioInputWrapper input[type="radio"]:checked::before {
  transform: scale(1);
}


.radioIcon {
  padding: 6px 0px 0px 6px;
  margin: auto;
  color: var(--color-on-surface);
}

/* .icon {
  font-size: 32px;
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 50%;
  transition: border 0.3s;
} */

.star {
  color: gold;
}

.circle {
  color: blue;
}

.square {
  color: green;
}