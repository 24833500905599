.customButton {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  /* text-transform: uppercase; */
  /* letter-spacing: 0.5px; */
  color: var(--color-on-primary);
  background-color: var(--color-primary);
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
  transition: background-color 0.3s, box-shadow 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
}
.fullWidth {
  width: 100%;
}

.customButton:hover {
  background-color: var(--color-primary);
  filter: brightness(0.9);
  /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); */
}

.customButtonLoading:hover{
  filter: none !important;
}

.customButton:active {
  background-color: var(--color-primary);
  /* box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); */
}

.customButton:focus {
  outline: none;
}

.spinning {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin var(--transition-slow) linear infinite;
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}