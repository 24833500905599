.colorPickerDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-on-surface);
  font-family: sans-serif;
  /* margin: 0px 0px 12px 0px; */
}


.colorPickerCircleWrapper {
  border: 1px solid var(--color-on-surface);
  border-radius: 50%;
  cursor: pointer;
  /* boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.4)", */
  /* box-shadow: 0px 0px 1px 2px var(--color-surface); */
}
