.ratingInputWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ratingStar {
  display: flex;
  align-items: center;
  transition: color (--transition-main) linear;
  color: var(--color-primary);
  margin: 4px;
}

.ratingStar:hover {
  cursor: pointer;
  transform: scale(1.2);
}