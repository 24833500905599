/* ========================================================================= */
/* REMOVE BLOCK BUTTON */
/* ========================================================================= */
.removeBlockButtonWrapper {
  position: absolute;
  top: 12px;
  display: flex;
  z-index: 1;
}
.removeBlockButtonWrapperNormal {
  right: 8px;
  flex-direction: row;
  justify-content: flex-start;
}
.removeBlockButtonWrapperFlipped {
  left: 8px;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.removeBlockButtonWrapper > .removeBlockButtonTitle {
  padding: 5px 8px;
  height: 24px;
  background-color: #eee;
  font-size: 13px;
  color: #333;
  border-radius: 3px;
  /* margin-right: 8px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.removeBlockButtonWrapper > .removeBlockButtonButton {
  background-color: #ff5252;
  border: none;
  border-radius: 3px;
  height: 24px;
  width: 36px;
  color: white;
  margin: 0px 4px;
}
.removeBlockButtonWrapper > .removeBlockButtonButton:hover {
  background-color: #ff1744;
  cursor: pointer;
}

/* ========================================================================= */
/* REMOVE BLOCK BUTTON */
/* ========================================================================= */