/* Start Footer */
.footer {
  width: 100%;
  /* background-color: aliceblue; */
  border-top: 1px solid var(--color-primary);
  padding: 24px 0px;
  text-transform: capitalize;
  text-align: center;
  background-color: var(--color-background);
  color: var(--color-on-background);
  font-size: 17px;
}
.footer a {
  font-weight: 500;
  color: var(--color-secondary);
}

.footerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.footerColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin-bottom: 1rem;
}

.donateButton {
  font-size: 1rem;
  background-color: var(--color-primary);
  color: var(--color-on-primary) !important;
  padding: 12px 24px;
  border-radius: 5px;
}

@media (min-width: 900px) {
  .footerContent {
    flex-direction: row;
  }
  .footerColumn {
    margin-bottom: 0px;
  }
}
