.contactPageWrapper {
  height: calc(100vh - 156px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
}

.contactCard {
  padding: 12px 12px 0px 12px;
  border-radius: 6px;
  width: 95vw;
  box-shadow: var(--box-shadow);
  background-color: var(--color-surface);
}

.contactPageHeader {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  margin: 12px 0px 24px 0px;
  color: var(--color-primary);
}

@media screen and (min-width: 768px) {
  .contactCard {
    padding: 24px 24px 0px 24px;
    border-radius: 12px;
    width: 40vw;
  }
}
