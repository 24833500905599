:root {
  --scale-factor-mobile: 0.4;
  --scale-factor-tablet: 0.7;
}

#resumePageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-background);
}

#resumeOuterWrapper {
  background-color: #fff;
  margin: 20px 0px;
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  transform: scale(var(--scale-factor-mobile));
  transform-origin: top center;
  margin-bottom: calc(-250% * (1 - var(--scale-factor-mobile)));
  height: auto;
  overflow: hidden;
}

#resumeInnerWrapper {
  position: relative;
  width: 49.8rem;
  aspect-ratio: 1 / 1.414;
  /* height: 297mm; */
  /* size: a4 !important; */
  margin: 20px 5px 0px 20px;
}

#resumeOptions {
  margin: 20px;
  display: flex;
  flex-direction: row;
}
.resumeOptionsIconButtons {
  background-color: var(--color-surface) !important;
  border: 2px solid var(--color-primary) !important;
  border-radius: 50%;
  color: var(--color-primary) !important;
  margin: 0px 6px 12px 6px;
}
.resumeOptionsIconButtons:hover {
  background-color: var(--color-primary) !important;
  color: var(--color-on-primary) !important;
  transition: background-color var(--transition-main), color var(--transition-main);
}

#shareModal {
  width: 300px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
#shareModalHeader {
  padding: 8px 0px 8px 24px;
  color: #454545;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}
#shareModalHeader > .closeIconButton {
  border-radius: 50%;
}

.shareListItem {
  width: 100%;
  padding: 16px 16px 16px 24px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #454545;
  font-size: 0.8rem;
}
.shareListItem:hover {
  transition: 200ms;
  cursor: pointer;
  background-color: #eee;
}

#shareCopyUrl {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 240px;
  margin: 16px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}
#shareCopyUrl:hover {
  transition: 200ms;
  cursor: pointer;
  background-color: #eee;
}

@media screen and (min-width: 600px) {
  #resumePageWrapper {
    flex-direction: row;
    justify-content: center;
    align-items: start;
  }
  #resumeOuterWrapper {
    /* background-color: red; */
    transform: scale(var(--scale-factor-tablet));
    transform-origin: top left;
    margin-right: calc(-100% * (1 - var(--scale-factor-tablet)));
  }
  #resumeOptions {
    flex-direction: column;
  }
}

@media screen and (min-width: 900px) {
  #resumeOuterWrapper {
    background-color: #fff;
    /* margin is needed to reset old margins */
    margin: 20px 0px;
    transform: none;
    overflow: hidden;
  }
}
