.collapse-container {
  overflow: hidden;
  transition: height 0.3s ease;
  height: 0; /* Default to collapsed state */
}

.collapse-enter {
  height: auto; /* Transition will overwrite this */
}

.collapse-content {
  padding: 0px 8px; /* Optional: add some padding inside */
}
