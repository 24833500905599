.sectionContainer {
  background-color: var(--color-background);
  width: 100%;
  /* height: 80vh; */
  padding: 10vh 0 20vh 0;
}

.benefitsCardsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.benefitsCard {
  height: 250px;
  width: 420px;
  padding: 24px;
  border-radius: 5px;
  background-color: var(--color-surface);
  box-shadow: var(--box-shadow);
}
.cardHeaderText {
  font-size: 24px;
  color: #333;
  color: var(--color-on-surface-header);
  margin-left: 0px;
}
.cardHeaderEmoji {
  font-size: 28px;
  margin-right: 4px;
}
.cardMainText {
  font-size: 1.125rem;
  color: #555;
  color: var(--color-on-surface);
  margin-top: 8px;
  margin-left: 8px;
  line-height: 1.75rem;
}
.cardBottomLine {
  font-size: 1.125rem;
  color: var(--color-primary);
  margin-top: 8px;
  margin-left: 8px;
  line-height: 1.75rem;
  letter-spacing: 0.08rem;
}

@media (max-width: 1200px) {
  .benefitsCardsWrapper {
    flex-direction: column;
  }
  .benefitsCard {
    margin-bottom: 36px;
  }
}

@media (max-width: 900px) {
  .benefitsCardsWrapper {
    flex-direction: column;
  }
  .benefitsCard {
    height: 250px;
    width: 90%;
    padding: 24px;
  }
}
