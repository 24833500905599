.button-group {
  display: inline-flex;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
}
.button {
  background-color: var(--color-surface);
  border: none;
  color: var(--color-on-surface);
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.3s;
}
.button:not(:last-child) {
  border-right: 1px solid var(--color-on-primary);
}
.button:hover:not(:disabled) {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
}
.button:disabled {
  cursor: not-allowed;
  filter: brightness(0.8);
  /* opacity: 0.5; */
}
.button.active {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
}