.photoFormBody {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 2rem;
}

.photoFormLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.photoFormRight {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #666;
}

.photoFormUploadButton {
  padding: 12px 48px;
  border: none;
  border-radius: 8px;
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  box-shadow: 0px 1px 2px 1px #dadada;
  transition: filter var(--transition-main) ease;
}
.photoFormUploadButton:hover {
  cursor: pointer;
  filter: brightness(0.8);
  transition: filter var(--transition-main) ease;
}

@media screen and (min-width: 800px) {
  .photoFormBody {
    flex-direction: row;
  }
  .photoFormLeft {
    width: 50%;
  }
  .photoFormRight {
    width: 50%;
  }
}