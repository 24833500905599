.gridContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}

.gridItem {
  box-sizing: border-box;
  /* Adjust padding as needed */
  padding: 0px 8px;
  flex: 1;
}

/* Extra Small Devices (phones, less than 576px) */
.col-xs-1  { flex: 0 0 8.3333%; max-width: 8.3333%; }
.col-xs-2  { flex: 0 0 16.6667%; max-width: 16.6667%; }
.col-xs-3  { flex: 0 0 25%; max-width: 25%; }
.col-xs-4  { flex: 0 0 33.3333%; max-width: 33.3333%; }
.col-xs-5  { flex: 0 0 41.6667%; max-width: 41.6667%; }
.col-xs-6  { flex: 0 0 50%; max-width: 50%; }
.col-xs-7  { flex: 0 0 58.3333%; max-width: 58.3333%; }
.col-xs-8  { flex: 0 0 66.6667%; max-width: 66.6667%; }
.col-xs-9  { flex: 0 0 75%; max-width: 75%; }
.col-xs-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
.col-xs-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
.col-xs-12 { flex: 0 0 100%; max-width: 100%; }

/* Small Devices (tablets, 576px and up) */
@media (min-width: 576px) {
  .col-sm-1  { flex: 0 0 8.3333%; max-width: 8.3333%; }
  .col-sm-2  { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-sm-3  { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4  { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-sm-5  { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-sm-6  { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7  { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-sm-8  { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-sm-9  { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-sm-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
}

/* Medium Devices (desktops, 768px and up) */
@media (min-width: 768px) {
  .col-md-1  { flex: 0 0 8.3333%; max-width: 8.3333%; }
  .col-md-2  { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-md-3  { flex: 0 0 25%; max-width: 25%; }
  .col-md-4  { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-md-5  { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-md-6  { flex: 0 0 50%; max-width: 50%; }
  .col-md-7  { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-md-8  { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-md-9  { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-md-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
}

/* Large Devices (large desktops, 992px and up) */
@media (min-width: 992px) {
  .col-lg-1  { flex: 0 0 8.3333%; max-width: 8.3333%; }
  .col-lg-2  { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-lg-3  { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4  { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-lg-5  { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-lg-6  { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7  { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-lg-8  { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-lg-9  { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-lg-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
}