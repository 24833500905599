#welcomeSection {
  background-color: var(--color-background);
}

.welcomeWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.welcomeText {
  text-align: start;
  flex: 1;
}

#welcomeHeader {
  font-size: 5rem;
  line-height: 1.3;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--color-primary);
}

#welcomeSubheader {
  font-size: 24px;
  text-transform: capitalize;
  color: var(--color-secondary);
  font-weight: 500;
}

#welcomeSubText {
  margin-top: 14px;
  max-width: 600px;
  line-height: 1.7;
  font-size: 24px;
  color: var(--color-on-background);
}

.chooseTemplateButton {
  /* background-color: var(--secondary-color); */
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary) !important;
  border-radius: 4px;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 12px 30px;
}
.chooseTemplateButton:hover {
  /* background-color: var(--dark-secondary-color); */
  background-color: var(--color-secondary);
  color: #ffffff !important;
  transition: 300ms;
}

.buildResumeButton {
  background-color: var(--color-primary);
  color: var(--color-on-primary) !important;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 12px 30px;
}
.buildResumeButton:hover {
  /* background: var(--dark-primary-color); */
  /* background: var(--color-background) !important; */
  filter: brightness(70%);
  color: var(--color-on-primary) !important;
  border: 1px solid var(--color-primary);
  transition: 300ms;
}

@media (max-width: 1200px) {
  /* .welcomeWrapper {
    flex-direction: column-reverse;
  } */
  #welcomeHeader {
    font-size: 3rem;
  }
  #welcomeSubheader {
    font-size: 1rem;
  }
  #welcomeSubText {
    font-size: 1rem;
  }
  .chooseTemplateButton {
    font-size: 1rem;
  }
  .buildResumeButton {
    font-size: 1rem;
  }
  /* .welcomeImage {
    display: none;
  } */
}

@media (max-width: 900px) {
  /* .welcomeWrapper {
    flex-direction: column-reverse;
  } */
  #welcomeHeader {
    font-size: 3rem;
  }
  #welcomeSubheader {
    font-size: 1rem;
  }
  #welcomeSubText {
    font-size: 1rem;
  }
  .chooseTemplateButton {
    font-size: 0.8rem;
  }
  .buildResumeButton {
    font-size: 0.8rem;
  }
  .welcomeImage {
    display: none;
  }
}
