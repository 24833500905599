.sectionHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 48px;
}

.supportHeader {
  color: var(--color-primary);
  font-size: 0.8rem;
  line-height: 1rem;
  letter-spacing: 0.08rem;
  font-weight: 600;
}

.mainHeader {
  color: var(--color-on-background);
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  margin-top: 4;
}
