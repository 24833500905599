.pageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-background);
  color: var(--color-primary);
}

.componentWrapper {
  /* width: 100vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.paper {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconAvatar {
  margin: 2px;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  background-color: var(--color-primary);
  color: var(--color-on-primary);
}

.authHeader {
  font-size: 24px;
  font-weight: 500;
  color: var(--color-primary);
}

.errorSection {
  color: var(--color-error);
  background-color: #ffcdd2;
  padding: 16px 32px;
  margin: 24px 0px 16px 0px;
  font-size: 16px;
  font-weight: 500;
  /* border: 1px solid #68121b; */
  border-radius: 8px;
}

/* styles.css */
.customTextfield .MuiFilledInput-root {
  background-color: var(--color-background); /* Adjust the background if needed */
  color: var(--color-on-background); /* Adjust the text color if needed */
}

.customTextfield .MuiFilledInput-root:before {
  border-bottom: 2px solid var(--color-primary); /* Default border color */
  /* eyeCatchingColor: #ff5722 */
}

.customTextfield .MuiFilledInput-root:hover:before {
  border-bottom: 2px solid var(--color-primary); /* Border color on hover */
}

.customTextfield .MuiFilledInput-root.Mui-focused:before,
.customTextfield .MuiFilledInput-root.Mui-focused:after {
  border-bottom: 2px solid var(--color-primary); /* Border color when focused */
}

.customTextfield .MuiInputLabel-root {
  color: var(--color-primary); /* Change label color */
}

.customTextfield .MuiInputLabel-root.Mui-focused {
  color: var(--color-primary); /* Change label color when focused */
}

.submit {
  margin: 3px 0px 2px 2px;
  background-color: var(--color-primary) !important;
  color: var(--color-on-primary) !important;
}

.form {
  width: 80vw;
  margin: 8px;
}

.miscOptions {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.miscOptions a {
  color: var(--color-primary);
  font-size: 12px;
}
.miscOptions a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 600px) {
  .form {
    width: 40vw;
  }
}

@media screen and (min-width: 960px) {
  .form {
    width: 20vw;
  }
}
