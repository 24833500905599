.colorFormWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid var(--color-on-surface);
  border-radius: 4px;
  padding: 16px;
  margin: 4px 0px;
  color: var(--color-on-surface);
}
.colorFormWrapper > div {
  margin: 8px;
}
.expandMoreIconButton {
  background-color: var(--color-muted);
  color: var(--color-on-surface);
  padding: 4px 24px;
  border-radius: 4px;
  height: min-content;
  width: 80px;
}

.removeFieldsButton {
  color: var(--color-error) !important;
}

@media screen and (min-width: 768px) {
  .colorFormWrapper {
    flex-direction: row;
  }
  .colorFormWrapper > div {
    margin: 0px 8px;
  }
}
