/* ========================================================================= */
/* THE OG RGL STUFF, DO NOT TOUCH */
/* ========================================================================= */

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
}

.react-grid-item.react-grid-placeholder {
  background: #000;
  opacity: 0.1;
  transition-duration: 100ms;
  z-index: 2;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* .react-grid-item:hover > .react-resizable-handle {} */
.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  /* background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4="); */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' stroke='%235b6be6' stroke-width='1' class='bi bi-chevron-down' viewBox='0 0 16 16' transform='rotate(-45)'%3E%3Cpath d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  /* color: #f56077; */
}

/* ========================================================================= */
/* CUSTOM MIDDLEGRID STYLES */
/* ========================================================================= */
#gridLayoutWrapper {
  width: 100%;
  margin: 0px 0px 0px 0px;
  /* padding-left: 1rem;
  padding-right: 1rem; */
  /* scale: (0.4,1.4); */
}

.gridBlocks {
  z-index: 1;
  cursor: move;
  cursor: grab;
  /* do not delete the boxShadow, it is used to show the blocks */
  /* box-shadow: "3px 3px 4px #d5d5d7, -3px -3px 8px #f9f9fb"; */
  border: 1px solid var(--color-muted);
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  /* scale: (1.3,0.4); */
}
.gridBlocks:active {
  cursor: grabbing;
}

.suggestiveEnd {
  position: relative;
  width: 100%;  
  height: 70px;
  /* background-color: rgb(255, 216, 216); */
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
    #ff5959 10px,
    #ff5959 16px
  );
}
#suggestiveEnd1 {
  top: 280mm;
}
#suggestiveEnd2 {
  top: calc((290mm*2) - 120px);
}


@media screen and (min-width: 600px) {
  #gridLayoutWrapper {
    /* width: 180mm; */
  }
  .gridBlocks {
    scale: 1;
  }
}

@media screen and (min-width: 900px) {
  #gridLayoutWrapper {
    /* width: 180mm; */
  }
  .gridBlocks {
    scale: 1;
  }
}

@media screen and (min-width: 1200px) {
  #gridLayoutWrapper {
    width: 210mm;
  }
  .gridBlocks {
    scale: 0.9;
  }
}

@media screen and (min-width: 1600px) {
  #gridLayoutWrapper {
    width: 210mm;
  }
  .gridBlocks {
    scale: 1;
  }
}
