.select-container {
  position: relative;
  width: 100%;
}

.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--color-surface);
  border: 1px solid var(--color-primary);
  color: var(--color-on-surface);
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.select-box.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select-label {
  flex-grow: 1;
}

.select-arrow {
  transition: transform 0.3s ease;
}

.select-box.open .select-arrow {
  transform: rotate(180deg);
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--color-surface);
  border: 1px solid var(--color-primary);
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  z-index: 1000;
  transition: max-height var(--transition-main) ease, opacity var(--transition-main) ease;
  max-height: 0;
  opacity: 0;
  font-weight: 400;
}

.options-list.open {
  max-height: max-content; /* Adjust this value based on your needs */
  opacity: 1;
  transition: max-height var(--transition-main) ease, opacity var(--transition-main) ease;
}

.option {
  padding: 10px;
  cursor: pointer;
}

.option:hover {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
}