/* FOR SLIDER COMPONENT */
.sliderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-on-surface);
  padding: 0px;
  margin: 28px 0px 0px 0px;
  width: 100%;
}
.slider {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--color-primary);
  outline: none;
  transition: opacity 0.2s;
  margin: 0px 0px 8px 0px;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: var(--color-primary);
  cursor: pointer;
  border-radius: 50%;
  border: none;
}
.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: var(--color-primary);
  cursor: pointer;
  border-radius: 50%;
  border: none;
}