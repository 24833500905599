.throbber {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-background);
  z-index: 99999;
}

.cardWrapper {
  height: 48px;
  width: 48px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 60px;
}

.cardWrapper::before {
  background: conic-gradient(
    transparent 120deg, 
    var(--color-primary) 360deg
  );
  position: absolute;
  left: -25%;
  top: -25%;
  height: 150%;
  width: 150%;
  animation: borderSpin 2s linear infinite;
  content: "";
}

@keyframes borderSpin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.cardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  position: absolute;
  left: 2px;
  top: 2px;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  border-radius: 50%;
  background-color: var(--color-surface);
}

.textWrapper {
  font-size: 1.2rem;
  line-height: 2.25rem;
  font-family: serif;
  user-select: none;
}