/* Start Global Rules */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}
*::placeholder {
  font-size: 15px;
  font-family: "poppins", sans-serif;
  font-weight: 500;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}
a {
  text-decoration: none;
}
/* a:active {
  transform: scale(0.91);
  transition: transform var(--f-transition);
} */
p {
  line-height: 1.5;
}
ul,
ol {
  list-style: none;
}
img {
  display: block;
  max-width: 100%;
  background-size: cover;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}
/* Small Screens */
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
/* Medium Screens */
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
/* Large Screens */
@media (min-width: 1200px) {
  .container {
    width: 1366px;
  }
}
/* End Global Rules */

/* Start Utility Classes */
.scale-effect:active {
  transform: scale(0.88);
  transition: transform var(--f-transition);
}
.fill::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -6px;
  width: 0;
  height: 3px;
  background: var(--color-secondary);
  transition: var(--main-transition);
}
.fill:hover::after {
  width: 100%;
}
.section-title {
  width: fit-content;
  max-width: 400px;
  margin: auto;
  margin-bottom: 50px;
  position: relative;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 35px;
  color: var(--color-primary);
  cursor: pointer;
}

.btns-group {
  margin-top: 20px;
  display: flex;
  text-align: center;
  gap: 10px;
}
/* End Utility Classes */

/* Start Landing Section */
.landing {
  height: 100vh;
  color: #333;
}
.landing .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 90%;
}
