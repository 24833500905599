/* Start Header */
.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 72px;
  display: flex;
  justify-content: center;
  background-color: var(--color-background);
  color: var(--color-primary);
  z-index: 99;
  border-bottom: 1px solid var(--color-primary);

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar .logo {
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: none;
  margin: 0px 12px 0px 12px;
}
.navbar .logo:active {
  transform: scale(1);
}
.navLinks a {
  display: none;
}
.themeToggleButton {
  display: none !important;
}
.profileButton {
  display: none !important;
}

.drawerButtons {
  padding: 6px 24px;
  margin-top: 16px;
  text-transform: none;
  color: var(--color-on-primary);
}

.toggleDrawerMenuButton {
  margin: 0px 12px 0px 0px;
  border-radius: 8px;
  text-transform: none;
  color: var(--color-on-primary);
  background-color: var(--color-primary);
}

.drawerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  padding: 1.5rem;
}

.drawerWrapper .buttonWrapper {
  width: 100% !important;
  margin: 0px 0px 8px 0px;
}


@media screen and (min-width: 900px) {
  .toggleDrawerMenuButton {
    display: none !important;
  }
  .navbar .logo {
    margin: 0px 12px 0px 96px;
  }
  .navLinks {
    transition: var(--transition-main);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .navLinks a {
    position: relative;
    display: inline-block;
    text-transform: capitalize;
    margin-right: 25px;
    /* color: var(--primary-color); */
    /* cause visited links are colored inherit in createpage.css */
    color: var(--color-primary);
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
  }
  /* the border animation on hover */
  .navLinks a::after {
    content: "";
    display: block;
    width: 0;
    height: 3px;
    transition: width var(--transition-main);
  }
  .navLinks a:hover::after {
    width: 100%;
  }
  .themeToggleButton {
    display: inline-flex !important;
    color: var(--color-primary);
    border-radius: 50% !important;
  }
  .profileButton {
    display: inline-flex !important;
    color: var(--color-primary);
    border-radius: 50% !important;
  }
  .navbar .logo {
    font-size: 1.3rem;
  }
}


@media screen and (min-width: 1200px) {
  .navbar .logo {
    margin: 0px 12px 0px 128px;
  }
}
@media screen and (min-width: 1600px) {
  .navbar .logo {
    margin: 0px 12px 0px 256px;
  }
}